import dashboard from './dashboard';
import pages from './pages';
import reportes from './reportes';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //
// Documentacion anada other
//dashboard,
const menuItems = {
    items: [ dashboard,pages, reportes ]
};

export default menuItems;
