// assets
import {  IconCategory, IconSocial } from '@tabler/icons';
// IconPalette,
//IconTypography,
//IconShadow,
const icons = {
    IconCategory,
    IconSocial
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const reportes = {
    id: 'reportes',
    title: 'Informes',
    type: 'group',
    children: [
        {
            id: 'util-profesion',
            title: 'Por Profesión',
            type: 'item',
            url: '/utils/totalesCategoria',
            icon: icons.IconCategory,
            breadcrumbs: false
        },
        {
            id: 'publicaciones',
            title: 'Publicaciones',
            type: 'item',
            url: '/publicaciones',
            icon: icons.IconSocial,
            //external: true,
            //target: true
        },
        
    ]
};

export default reportes;
