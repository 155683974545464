// assets
import { IconDashboard,IconListCheck, IconFlag } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconListCheck, IconFlag };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'tasks',
            title: 'Tareas',
            type: 'item',
            url: '/tareas',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
        {
            id: 'reportados',
            title: 'Publicaciones Reportadas',
            type: 'item',
            url: '/reportados',
            icon: icons.IconFlag,
            //external: true,
            //target: true
        },
        
    ]
};

export default dashboard;
