
import axios from 'axios';
import FormData from 'form-data';

//Esta es la librería que se usa para hacer y recibir todas las peticiones post y get al API
export default class WebApi  {
    //si desea consultar solo dashboard categoria.json?dashboard=true
    //todas las url y direcciones de la API
    SHOW_ALERT= true;
    _BASE_URL= "https://api.afiservicios.com/v3/";
    _IMAGES_URL="https://api.afiservicios.com/";

    _SAMPLE= 'https://';

    USER_LOGIN='admin_login.json';
    USUARIO_CREAR='usuario_crear.json';
    USUARIOS='usuarios.json';
    USUARIO_PERFIL_UPDATE='usuario_perfil_update.json';
    PROFESIONALES='usuarios_profesionales.json';
    ELIMINADOS='usuarios_eliminados.json';
    PUBLICACIONES='publicaciones.json';
    REPORTADOS='reportados.json';
    USUARIO_DELETE='usuario_delete.json';
    USER_ELIMINAR_CUENTA='user_eliminar_cuenta.json';
    HACER_ADMIN='usuario_toAdmin.json';
    CATEGORIAS='categoria.json';
    POR_CATEGORIAS='porCategoria.json';
    POR_CATEGORIAS_DESGLOSE='porCategoriaDesglose.json';
    CATEGORIA_NUEVA='categoria_nueva.json';
    CATEGORIA_DELETE='categoria_delete.json';
    STATS = 'stats.json';
    GENERAL_DATA='generalData.json';
    GENERAL_TOTAL_SEXO='generalTotalsSexo.json';
    POSTS_UPDATE_STATUS='reportadosActualizar.json';
    CIUDADES='ciudad.json';
    SERVICIOS_AFI='servicios_afi.json';
    SERVICIOS_AFI_UPDATE='servicios_afi_update.json';
    SERVICIOS_AFI_UPDATE_STATUS='servicios_afi_update_status.json';
    NOTIFICATION_SEND='sendNotification.json';
    TASK='tareas.json';

    async sendGetRequest(_url, _params, external=false) {
        if(!navigator.onLine){
          alert("Al parecer tiene problemas con su conexión a internet");
          let err = [];
          err.error = "Al parecer tiene problemas con su conexión a internet";
          err.no_result = true;
          return err;
        }
        //this.url();
        if(!external){
            _url = this._BASE_URL + _url;
        }

        console.log("API _url", _url);

        try {
            let response = await axios.get(_url, {
                data: _params ? _params : null,
                timeout: 15000
            });

            console.log("API call response", response)
            return response;

        } catch (error) {
            let err = [];
            err.error = error;
            err.no_result = true;
            console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("No se han encontrado resultados");
                console.log("No se han encontrado resultados");
            }, 400)
            return err;
        }
    }

    async sendPostRequest(_url, _params) {
        if(!navigator.onLine){
          alert("Al parecer tiene problemas con su conexión a internet");
          let err = [];
          err.error = "Al parecer tiene problemas con su conexión a internet";
          err.no_result = true;
          return err;
        }
        _url = this._BASE_URL + _url;
        console.log("API _url", _url);

        if (!_params) {
            _params = {}
        }
        try {
            let response = await axios({
                method: 'post',
                url: _url,
                data: _params,
                timeout: 15000
            });
            console.log("API call response", response);
            return response;

        } catch (error) {
            let err = [];
            err.error = error;
            err.no_result = true;
            console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("No hay resultados para mostrar");
            }, 400)
            return err;
        }
    }

    async sendPostFileRequest(_url, _params,file) {
      if(!navigator.onLine){
        alert("Al parecer tiene problemas con su conexión a internet");
        let err = [];
        err.error = "Al parecer tiene problemas con su conexión a internet";
        err.no_result = true;
        return err;
      }
      _url = this._BASE_URL + _url;
      //console.log("API FILE _url", _url );
      const form_data = new FormData();
      //var imageType = /image.*/;
      //if (!file.type.match(imageType)) return;
      form_data.append('file', file);
      /*if(typeof file.name!=="undefined"){
        form_data.append('file', file, file.name);
      }*/

      for (const prop in _params){
         form_data.append(`${prop}`, `${_params[prop]}`);
      }

      /*for (var key of form_data.entries()) {
       console.log(key[0] + ', ' + key[1]);
     } */
      try {
         let response = await axios({
               method: 'post',
               url: _url,
               data: form_data,
               timeout: 15000,
               headers: {
               'Content-Type': 'multipart/form-data',
               }
           });

          console.log("API call response", response);
          return response;
      } catch (error) {
          let err = [];
          err.error = error;
          err.no_result = true;
          console.log("catch error on ", _url, " call fail", err);
          setTimeout(() => {
              this.SHOW_ALERT && alert('No se han encontrado resultados');
          }, 400)
          return err;
      }
    }

    editProfile(user_id,
      nombre, apellido, email, direccion, cedula, telefono,
            pais, permiso, contrasenia){
        let url= this._SAMPLE +
            "?user_id=" + user_id +
            "&nombre=" + nombre +
            "&apellido=" + apellido +
            "&email=" + email +
            "&direccion=" + direccion +
            "&cedula=" + cedula +
            "&telefono=" + telefono +
            "&pais=" + pais +
            "&permiso="+permiso +
            "&contrasenia="+contrasenia
            ;
            return this.sendGetRequest(url)
    }

    editDestinatario(user_id,nombre,cedula){
        let url= this.DESTINATARIO_SET +
            "?user_id=" + user_id +
            "&nombre=" + nombre  +
            "&cedula=" + cedula
            ;
            return this.sendGetRequest(url)
    }

    getReportados(search,pag){
      let url= this.REPORTADOS +
          `?dashboard=true&search=${search}&pag=${pag}`;
          return this.sendGetRequest(url);
    }

    getLogs(search,pag){
      let url= 'logs.json' +
          `?dashboard=true&search=${search}&pag=${pag}`;
          return this.sendGetRequest(url);
    }

    getTareas(search,pag){
      let url= this.TASK +
          `?dashboard=true&search=${search}&pag=${pag}`;
          return this.sendGetRequest(url);
    }

    setCheckedTask(id) {
      let url = this.TASK + `?dashboard=true`;
      let _params = `id=${id}`;
      return this.sendPostRequest(url, _params);
    }

    getPublicaciones(search,pag){
      let url= this.PUBLICACIONES +
          `?dashboard=true&search=${search}&pag=${pag}`;
          return this.sendGetRequest(url);
    }

    getProfile(id){
        let url = this._SAMPLE + "?id="+id
        return this.sendGetRequest(url)
    }

    postUpdateStatus(id, action, ejecutor){ //id de reportado
      let url = this.POSTS_UPDATE_STATUS+`?dashboard=true&id=${id}&action=${action}&ejecutor=${ejecutor}`;
      return this.sendGetRequest(url);
    }

    userCreate(nombre, apellido, email, password){
      let url= this.USUARIO_CREAR + `?dashboard=true&nombre=${nombre}&apellido=${apellido}&email=${email}&password=${password}`;
      return this.sendGetRequest(url);
    }

    userLogin(_email, _password) {
        //?email=mail@ejemplo.com&pass=xxxxxx
        let url = this.USER_LOGIN + "?dashboard=true&email=" + _email + "&pass=" + _password;
        return this.sendGetRequest(url)
    }

    getUsers(search, pag, permiso=false) {
        let url = this.USUARIOS + `?dashboard=true&pag=${pag}&permiso=${permiso}&search=${search}`;
        return this.sendGetRequest(url);
    }

    getUser(id) {
      let url = this.USUARIOS + `?dashboard=true&usuario=${id}`;
      return this.sendGetRequest(url);
    }

    updateUsuarioAfi( values, file){
      let url = this.USUARIO_PERFIL_UPDATE+ `?dashboard=true`;
      let params = `id=${values.id}&nombre=${values.nombre}&apellido=${values.apellido}`
                    + `&telefono=${values.telefono}&email=${values.email}`
                    + `&direccion=${values.direccion}&ruc=${values.ruc}`;
      if(typeof file === 'undefined' || !file || file.length === 0){
        return this.sendPostRequest(url, params);
      }
      //console.log(file)
      return this.sendPostFileRequest(url,values, file);
    }


    getProfesionales(search, pag, permiso){
      let url = this.PROFESIONALES + `?dashboard=true&search=${search}&pag=${pag}&permiso=${permiso}`;
      return this.sendGetRequest(url);
    }

    getEliminados(search, pag, permiso){
      let url = this.ELIMINADOS + `?dashboard=true&search=${search}&pag=${pag}&permiso=${permiso}`;
      return this.sendGetRequest(url);
    }

    getServiciosAfi(search, pag, permiso){
      let url = this.SERVICIOS_AFI + `?dashboard=true&search=${search}&pag=${pag}`;
      return this.sendGetRequest(url);
    }

    updateServiciosAfi( values, file){
      let url = this.SERVICIOS_AFI_UPDATE+ `?dashboard=true`;
      let params = `id=${values.id}&nombre=${values.nombre}&apellido=${values.apellido}`
                    + `&telefono=${values.telefono}&email=${values.email}&tipo=${values.tipo}`
                    + `&direccion=${values.direccion}&cedula=${values.cedula}`;
      if(typeof file === 'undefined' || !file || file.length === 0){
        return this.sendPostRequest(url, params);
      }
      //console.log(file)
      return this.sendPostFileRequest(url,values, file);
    }

    updateServiciosAfiStatus(id, estado){
        let url = this.SERVICIOS_AFI_UPDATE_STATUS + `?dashboard=true&user_id=${id}&estado=${estado}`;
        return this.sendGetRequest(url);
    }
    //BUSCAR CLIENTES PARA  AUTOCOMPLETE
 
    getData(){
      let url = this.GENERAL_DATA + `?dashboard=true`;
      return this.sendGetRequest(url);
    }
    getTotalSexo(){

      let url = this.GENERAL_TOTAL_SEXO + `?dashboard=true`;
      return this.sendGetRequest(url);
    }

    

    updateStatus(id, estado, ejecutor){
      let url = this.USUARIO_DELETE + `?dashboard=true&user_id=${id}&estado=${estado}&ejecutor=${ejecutor}`;
        return this.sendGetRequest(url);
    }

    deleteUser(id, estado, ejecutor){
        let url = this.USUARIO_DELETE + `?dashboard=true&user_id=${id}&estado=${estado}&delete=true&ejecutor=${ejecutor}`;
        return this.sendGetRequest(url);
    }

    deleteUserForever(_id,_email, ejecutor) {
      let url = this.USER_ELIMINAR_CUENTA + `?id=${_id}&email=${_email}&pass=rZwJu8kOP8kfqnd&tipo=ADMIN&ejecutor=${ejecutor}`;
      return this.sendGetRequest(url)
  }

  hacerAdmin(id, ejecutor){
    let url = this.HACER_ADMIN + `?dashboard=true&user_id=${id}&ejecutor=${ejecutor}`;
    return this.sendGetRequest(url);
  }


    getPermissions(){
      let url = this._SAMPLE + `?status=A`;
      return this.sendGetRequest(url);
    }

    getCiudades(){
      let url = this.CIUDADES + `?status=A&getAll=true`;
      return this.sendGetRequest(url);
    }

    getAllCategorias(){
      let url = this.CATEGORIAS + `?status=A&getAll=true`;
      return this.sendGetRequest(url);
    }

    getReportePorCategorias(){
      let url = this.POR_CATEGORIAS + `?dashboard=true&status=A&getAll=true`;
      return this.sendGetRequest(url);
    }

    getProfesionalesDesglose(search, pag, permiso, categoria){
      let url = this.POR_CATEGORIAS_DESGLOSE + `?dashboard=true&search=${search}&pag=${pag}&permiso=${permiso}&categoria=${categoria}`;
      return this.sendGetRequest(url);
    }

    sendNotification(id, mensaje){
      let url= this.NOTIFICATION_SEND+`?dashboard=true&id=${id}&mensaje=${mensaje}` ;
      return this.sendGetRequest(url);

    }

    updateCategoria(id, nombre,icono){
      let url = this.CATEGORIA_NUEVA + '?dashboard=true';
      let _params = `id=${id}&nombre=${nombre}&icono=${icono}`;
      return this.sendPostRequest(url,_params);
    }

    deleteCategoria(id){
      let url = this.CATEGORIA_DELETE+ '?dashboard=true';
      let _params = `id=${id}`;
      return this.sendPostRequest(url,_params);
    }

    descodificarEntidad(str) {
        return str.replace(/&#(\d+);/g, function(match, dec) {
          return String.fromCharCode(dec);
        });
    }

    getReportesPrincipal(){
      let url = this.STATS;
      return this.sendGetRequest(url);
    }


    uploadImageCurso(id_curso,file){
      let url = this._SAMPLE ;
      let params = 'id='+id_curso+'&eliminar=true';
      if(typeof file === 'undefined' || file.length === 0){
        return this.sendPostRequest(url, params);
      }
      let _params = { 'id':id_curso };
      return this.sendPostFileRequest(url,_params, file[0]);
    }

    uploadFile(id_curso,file){
      let url = this._SAMPLE ;
      if(typeof file === 'undefined' || file.length === 0){
        return false;
      }
      let _params = { 'oficio':id_curso };
      return this.sendPostFileRequest(url,_params, file);
    }

}
